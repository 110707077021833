.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}

.wpcf7 form .wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: 2px solid #00a0d2; /* Blue */
}

.wpcf7 form.init .wpcf7-response-output,
.wpcf7 form.resetting .wpcf7-response-output,
.wpcf7 form.submitting .wpcf7-response-output {
  display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
  border-color: #46b450; /* Green */
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
  border-color: #dc3232; /* Red */
}

.wpcf7 form.spam .wpcf7-response-output {
  border-color: #f56e28; /* Orange */
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
  border-color: #ffb900; /* Yellow */
}

.wpcf7-form-control-wrap {
  position: relative;
}

.wpcf7-not-valid-tip {
  color: #dc3232; /* Red */
  font-size: 1em;
  font-weight: normal;
  display: block;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
  position: relative;
  top: -2ex;
  left: 1em;
  z-index: 100;
  border: 1px solid #dc3232;
  background: #fff;
  padding: 0.2em 0.8em;
  width: 24em;
}

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em;
}

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
  content: ' ';
}

.wpcf7-spinner {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d; /* Dark Gray 800 */
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 24px;
  position: relative;
}

form.submitting .wpcf7-spinner {
  visibility: visible;
}

.wpcf7-spinner::before {
  content: '';
  position: absolute;
  background-color: #fbfbfc; /* Light Gray 100 */
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion: reduce) {
  .wpcf7-spinner::before {
    animation-name: blink;
    animation-duration: 2000ms;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.wpcf7 input[type='file'] {
  cursor: pointer;
}

.wpcf7 input[type='file']:disabled {
  cursor: default;
}

.wpcf7 .wpcf7-submit:disabled {
  cursor: not-allowed;
}

.wpcf7 input[type='url'],
.wpcf7 input[type='email'],
.wpcf7 input[type='tel'] {
  direction: ltr;
}

.wpcf7-reflection > output {
  display: list-item;
  list-style: none;
}

.wpcf7-reflection > output[hidden] {
  display: none;
}

.wpcf7 .recaptcha-annotiation-area {
  margin-top: 28px;
  font-size: 11px;
  text-align: center;
}
.wpcf7 .recaptcha-annotiation-area span a {
  color: #0553dd;
  text-decoration: underline;
}
.wpcf7 .recaptcha-annotiation-area span br {
  display: none;
}
@media (max-width: 768px) {
  .wpcf7 .recaptcha-annotiation-area span br {
    display: block;
  }
}

.slogan-title {
  font-size: 50px;
  font-weight: bolder;
  text-align: center;
}

.slogan-button {
  margin-left: auto;
  margin-right: 220px;
}

.front-page .slogan-button.about__btn {
  width: 150px;
}
